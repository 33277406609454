/* .receipt {
  display: block;
  max-width: 360px;
  /* border-top: 5px dotted black;
  border-bottom: 5px dotted black; */
/* border-left: none;
  border-right: none;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
  * background-color: #f9f9f9; *
} */

.receipt {
  display: none;
  position: relative;
  width: 300px;
  height: 400px;
  padding: 20px;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='200' height='200'><text x='0' y='100' font-size='20' fill='rgba(0,0,0,0.5)' transform='rotate(-20)'>TULEDATA</text></svg>")
    repeat;
  background-size: 200px 200px;
}

/* .receipt::before {
  content: "PALMPAY";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
  font-size: 50px;
  color: rgba(0, 0, 0, 0.021); /* Faint watermark */
/* font-weight: bold; *
  white-space: nowrap;
} */

#invoice-POS {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  padding: 2mm;
  margin: 0 auto;
  width: 44mm;
  background: #ffffff;

  ::selection {
    background: #f31544;
    color: #fff;
  }
  ::moz-selection {
    background: #f31544;
    color: #fff;
  }
  h1 {
    font-size: 1.5em;
    color: #222;
  }
  h2 {
    font-size: 0.9em;
  }
  h3 {
    font-size: 1.2em;
    font-weight: 300;
    line-height: 2em;
  }
  p {
    font-size: 0.7em;
    color: #666;
    line-height: 1.2em;
  }

  #top,
  #mid,
  #bot {
    /* Targets all id with 'col-' */
    border-bottom: 1px solid #eee;
  }

  #top {
    min-height: 100px;
  }
  #mid {
    min-height: 80px;
  }
  #bot {
    min-height: 50px;
  }

  #top .logo {
    /* float: left; */
    height: 40px;
    width: 40px;
    background: url(../../../pictures/receipt_1.png) no-repeat;
    background-size: 40px 40px;
  }
  .clientlogo {
    float: left;
    height: 60px;
    width: 60px;
    background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
    background-size: 60px 60px;
    border-radius: 50px;
  }
  .info {
    display: block;
    /* float: left; */
    margin-left: 0;
  }
  .title {
    float: right;
  }
  .title p {
    text-align: right;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  td {
    /* padding: 5px 0 5px 15px; */
    /* border: 1px solid #eee; */
  }
  .tabletitle {
    /* padding: 5px; */
    font-size: 0.5em;
    background: #eee;
  }
  .service {
    border-bottom: 1px solid #eee;
  }
  .item {
    width: 24mm;
  }
  .itemtext {
    font-size: 0.5em;
  }

  #legalcopy {
    margin-top: 5mm;
  }
}

.header .logo {
  width: 50px;
  margin-bottom: 10px;
}

.header h1 {
  color: purple;
  margin: 5px 0;
}

.section {
  margin: 20px 0;
  text-align: left;
}

.section h2 {
  font-size: 16px;
  color: #555;
  margin-bottom: 5px;
}

.section p {
  margin: 3px 0;
}

footer {
  margin-top: 20px;
  font-size: 12px;
  color: #888;
}
